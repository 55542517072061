<template>
  <h4 class="VOUS-VOUS-DEMANDEZ-PEUT-TRE">VOUS VOUS DEMANDEZ PEUT-ÊTRE</h4>
  <h1>Quelques questions importantes !</h1>
  <!-- SOUS TITRE 1 -->
  <div
    v-for="(q, v) in questions"
    :key="v"
    @click="open[v] = !open[v]"
    style="cursor: pointer"
  >
    <h5>
      <span :class="{ 'icon-moins': open[v], 'icon-plus': !open[v] }"></span
      >{{ q.question }}
    </h5>
    <p :class="{ 'p-open': open[v], 'p-close': !open[v] }">
      {{ q.answer }}
    </p>
    <div
      style="width: 100%; height: 1px; border-bottom: 1px solid #e0e0e0"
    ></div>
  </div>

  <!-- SOUS TITRE 1 -->
  <!--h5>
    <span
      :class="{ 'icon-moins': open[1], 'icon-plus': !open[1] }"
      @click="open[1] = !open[1]"
    ></span
    >Comment les experts évaluent-ils mon bien ?
  </h5>
  <p :class="{ 'p-open': open[1], 'p-close': !open[1] }">
    Pour déterminer le prix d’un bien au plus juste, l’expert réalise une étude
    comparative du marché de l’immobilier : Dans votre ville, Ou dans votre
    quartier si votre logement se situe dans une grande agglomération.
    <br /><br />
    Pour ajuster son évaluation avec le marché, l’expert tient compte du prix
    moyen au mètre carré de votre bien qu’il va pondérer en fonction d’éléments
    qui caractérisent votre actif : l’état général, l’étage, la distribution,
    ses éléments d’équipements, sa superficie, etc. Un atout (vue dégagée, étage
    élevé, etc.) peut entrainer une surcote alors qu’un défaut (rez-de-chaussée
    bruyant, travaux à réaliser) peut entrainer une décote.
  </p>
  <div style="width: 100%; height: 1px; border-bottom: 1px solid #e0e0e0"></div>

  <h5>
    <span
      :class="{ 'icon-moins': open[2], 'icon-plus': !open[2] }"
      @click="open[2] = !open[2]"
    ></span
    >Les outils d’estimation en ligne sont-ils fiables ?
  </h5>
  <p :class="{ 'p-open': open[2], 'p-close': !open[2] }">
    Bien souvent, les outils d’estimation gratuits se basent sur des remontées
    déclaratives d’agences. Les estimations peuvent varier du simple au double.
    Ils ont pour principal objectif de récupérer vos coordonnées afin de vous
    recontacter dans un but commercial. En utilisant ExVal, vous vous assurer
    une estimation immobilière au plus juste. Grâce à sa base de données forte
    de plusieurs dizaines de millions de biens vendus, l’outil se base sur les
    transactions effectives.
  </p>
  <div style="height: 1px; border-bottom: 1px solid #e5ecf4"></div-->
</template>

<script>
import axios from "axios";
export default {
  name: "OutilExval",
  mounted() {
    const self = this;
    axios({
      method: "GET",
      url: "https://api.exval.fr/api/v1/static-page/faq",
    })
      .then(function (response) {
        if (response.data.code == 200) {
          const datas = response.data.datas;
          self.questions = datas;
          self.open = datas.map(function () {
            return true;
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  data() {
    return {
      open: [], //hasiana id fa tsy fantatra hoe qui est qui
      questions: [],
    };
  },
};
</script>

<style scoped>
.p-open {
  display: inline;
  height: auto;
  transition: height 0.5s ease-out;
}
.p-close {
  display: block;
  transition: height 0.3s ease-out;
  max-height: 0px;
  height: 0px;
  overflow: hidden;
}
.icon-moins {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #e94a73;
  border-radius: 20px;
  margin: 0px 15px 0px 0px;
  transform: translateY(3px);
}

.icon-moins::before {
  content: "";
  display: block;
  height: 2px;
  width: 14px;
  background: white;
  font-size: 1.2em;
  position: absolute;
  font-weight: 900;
  transform: translate(3px, 8.5px);
}

.icon-plus {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #e94a73;
  border-radius: 20px;
  margin: 0px 15px 0px 0px;
  transform: translateY(3px);
}

.icon-plus::before {
  content: "";
  display: block;
  height: 2px;
  width: 14px;
  background: white;
  font-size: 1.2em;
  position: absolute;
  font-weight: 900;
  transform: translate(3px, 8.5px);
}

.icon-plus::after {
  content: "";
  display: block;
  height: 14px;
  width: 2px;
  background: white;
  font-size: 1.2em;
  position: absolute;
  font-weight: 900;
  transform: translate(9px, 3px);
}

.VOUS-VOUS-DEMANDEZ-PEUT-TRE {
  margin-top: 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 3px;
  text-align: left;
  color: #e94a73;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.53;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}

h5 {
  margin: 30px 0px 0px 0px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: -0.5px;
  text-align: left;
  color: #363660;
  width: 100%;
}

p {
  content: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  transition: all 500ms;
}
</style>